<script lang="ts">
import { h } from 'vue';
import loadjs from 'loadjs';
const { isDefined } = loadjs; // eslint-disable-line import/no-named-as-default-member
export default defineComponent({
  name: 'SimplrChat',
  props: {
    widgetId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (!isDefined('simplr-chat')) this.loadScript();
  },
  methods: {
    loadScript() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const loadScript = loadjs('https://d25e3ekwgln440.cloudfront.net', 'simplr-chat', {
        before: () => {
          const scriptNode = document.createElement('script');
          scriptNode.innerHTML = `window.SimplrChatConfig = {
            widgetId: '${self.widgetId}'
          }`;
          document.head.appendChild(scriptNode);
        },
        returnPromise: true,
      });
      return loadScript
        .then(() => {
          return Promise.resolve(true);
        })
        .catch((error: unknown) => {
          console.warn(error); // eslint-disable-line no-console
          return Promise.resolve(false);
        });
    },
  },
  render() {
    return h('div');
  },
});
</script>
